<template>
  <div class="category-space">
    <vue-scroll :ops="ops" @handle-scroll="onListScroll">
      <div class="d-flex category-space__list">
        <div class="category-space__bar">
          <div
            v-for="item in sortList.bar1"
            :key="item.id"
            class="category-space__item"
            @click="onInspirationClick(item.id)"
          >
            <img :src="item.pic"/>
            <div class="category-space__item-text-container">
              <div v-html="breakLineHandle(item.title)" class="font-bold" :class="{'category-space__item-text-en': lang !== 'hk'}"></div>
            </div>
          </div>
        </div>
        <div class="category-space__bar">
          <div
            v-for="item in sortList.bar2"
            :key="item.id"
            class="category-space__item"
            @click="onInspirationClick(item.id)"
          >
            <img :src="item.pic"/>
            <div class="category-space__item-text-container">
              <div v-html="breakLineHandle(item.title)" class="font-bold" :class="{'category-space__item-text-en': lang !== 'hk'}"></div>
            </div>
          </div>
        </div>
        <div class="category-space__bar">
          <div
            v-for="item in sortList.bar3"
            :key="item.id"
            class="category-space__item"
            @click="onInspirationClick(item.id)"
          >
            <img :src="item.pic"/>
            <div class="category-space__item-text-container">
              <div v-html="breakLineHandle(item.title)" class="font-bold" :class="{'category-space__item-text-en': lang !== 'hk'}"></div>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <TouchTip />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import vuescroll from 'vuescroll'
import TouchTip from '@/components/common/TouchTip'

export default {
  name: 'CategorySpace',
  components: {
    'vue-scroll': vuescroll,
    TouchTip
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      ops: {
        rail: {
          background: '#9b9b9b',
          opacity: 0.56,
          size: '7px',
          specifyBorderRadius: '10px'
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#707070',
          specifyBorderRadius: true,
          size: '7px'
        }
      }
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('inspiration', ['category', 'category_en']),
    ...mapState('breadcrumb', ['breadcrumb']),
    sortList () {
      if (!this.category.length && !this.category_en.length) return { bar1: [], bar2: [], bar3: [] }

      const list = this.lang === 'hk' ? [...this.category] : [...this.category_en]
      const obj = {}

      obj.bar1 = list.filter((item, idx) => idx % 3 === 0)
      obj.bar2 = list.filter((item, idx) => idx % 3 === 1)
      obj.bar3 = list.filter((item, idx) => idx % 3 === 2)

      return obj
    }
  },
  created () {
    this.fetchShowroomList(this.id)
    this.fetchBigScreenPhase({ current: 'showroomType', current_id: this.id, lang: this.lang })
  },
  methods: {
    ...mapMutations('screensaver', ['RESET_TIMER']),
    ...mapActions('api', ['fetchShowroomList', 'fetchBigScreenPhase']),
    ...mapActions('gtagEvents', ['inspirationClickTrack']),
    onListScroll () {
      this.RESET_TIMER()
    },
    breakLineHandle (text) {
      return text.replaceAll('\n', '<br />')
    },
    onInspirationClick (id) {
      const gaCode = this.category_en.find(item => item.id === id).ga_code
      const name = this.breadcrumb.category_en.find(item => item.id === parseInt(this.id)).name
      this.inspirationClickTrack({ gaCode, name, lan: this.lang })
      this.$router.push({ name: 'CategoryProduct', params: { productId: id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-space {
  height: 71.1111vh;

  &__bar {
    width: 30.573vw;
    margin-right: 0.53125vw;
  }

  &__item {
    position: relative;
    width: 100%;
    margin-top: 1.074vh;

    &:nth-child(1) {
      margin-top: 0;
    }

    img {
      width: 100%;
    }
  }

  &__item-text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 17.51vh;
    @include bg-cover;
    background-image: url('~@/assets/img/category/blue-retangle.svg');
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.49);
    font-size: 2.03125vw;
    line-height: 1.23;
    letter-spacing: 0.061vw;
    color: $white;
    padding-top: 7.55556vh;
    padding-left: 1.328125vw;
  }

  &__item-text-en {
    font-size: 1.6667vw;
    line-height: 1.25;
    letter-spacing: -0.05vw;
  }
}
</style>
